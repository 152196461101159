import React from 'react';
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import FeaturedImage from "@js/component/FeaturedImage";
import Heading from "@js/component/Heading";
import Box from "@js/component/Box";
import Main from "@js/component/Main";
import clsx from "clsx";

import * as classNames from "@css/component/template/wordpress/Page.module.scss";
import {graphql, useStaticQuery} from "gatsby";

import { PageProps } from "gatsby";
import Employees from "@js/component/template/Employees";

import BoxCompare from "@js/component/BoxCompare";
import BreadCrumbs from "@js/component/BreadCrumbs";

/**
 * @type AboutUsPageData
 */
type AboutUsPageData = {
    wordpressPage: {
        content: string;
        featuredImage: {
            node: {
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                    publicURL: string;
                };
                altText: string;
                height: number;
                mimeType: string;
                sourceUrl: string;
                width: number;
            };
        };
        seo: {
            breadcrumbs: {
                text: string;
                url: string;
            }[];
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            title: string;
            twitterDescription: string;
            twitterTitle: string;
            schema: {
                raw: string;
            };
        };
        title: string;
    };
};

/**
 * @type AboutUsProps
 */
type AboutUsProps = PageProps;

/**
 * @const AboutUsPage
 */
const AboutUsPage = (props: AboutUsProps) => {
    const {
        pageContext
    } = props;

    const data: AboutUsPageData = useStaticQuery(graphql`
        query {
            wordpressPage(isAboutUsPage: { eq: true }) {
                content
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 1920, quality: 100)
                            }
                            publicURL
                        }
                        altText
                        height
                        mimeType
                        sourceUrl
                        width
                    }
                }
                seo {
                    breadcrumbs {
                        text
                        url
                    }
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    opengraphDescription
                    opengraphModifiedTime
                    opengraphPublishedTime
                    opengraphPublisher
                    opengraphSiteName
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    twitterDescription
                    twitterTitle
                    schema {
                        raw
                    }
                    title
                }
                title
            }
        }
    `);

    const OpenGraphImage = process.env.SITE_URL + data.wordpressPage.featuredImage?.node.localFile.publicURL;

    return (
        <Main>
            <Helmet>
                <title>{ data.wordpressPage.title }</title>

                { data.wordpressPage.seo.metaKeywords && <meta content={ data.wordpressPage.seo.metaKeywords } name="keywords" /> }
                { data.wordpressPage.seo.metaDesc && <meta content={ data.wordpressPage.seo.metaDesc } name="description" /> }

                <meta content={ `${ data.wordpressPage.seo.metaRobotsNofollow }, ${ data.wordpressPage.seo.metaRobotsNoindex }` } name="robots" />

                { data.wordpressPage.seo.opengraphDescription && <meta content={ data.wordpressPage.seo.opengraphDescription } property="og:description" /> }
                { data.wordpressPage.seo.opengraphModifiedTime && <meta content={ data.wordpressPage.seo.opengraphModifiedTime } property="article:modified_time" /> }
                { data.wordpressPage.seo.opengraphPublishedTime && <meta content={ data.wordpressPage.seo.opengraphPublishedTime } property="article:published_time" /> }
                { data.wordpressPage.seo.opengraphPublisher && <meta content={ data.wordpressPage.seo.opengraphPublisher } property="article:author" /> }
                { data.wordpressPage.seo.opengraphSiteName && <meta content={ data.wordpressPage.seo.opengraphSiteName } property="og:site_name" /> }
                { data.wordpressPage.seo.opengraphSiteName && <meta content={ data.wordpressPage.seo.opengraphSiteName } property="og:site_name" /> }
                { data.wordpressPage.seo.opengraphTitle && <meta content={ data.wordpressPage.seo.opengraphTitle } property="og:title" /> }
                { data.wordpressPage.seo.opengraphType && <meta content={ data.wordpressPage.seo.opengraphType } property="og:type" /> }
                { data.wordpressPage.seo.opengraphUrl && <meta content={ data.wordpressPage.seo.opengraphUrl } property="og:url" /> }

                <meta property="og:image" content={ OpenGraphImage } />
                <meta property="og:image:type" content={ data.wordpressPage.featuredImage?.node.mimeType } />
                <meta property="og:image:width" content={ data.wordpressPage.featuredImage?.node.width.toString() } />
                <meta property="og:image:height" content={ data.wordpressPage.featuredImage?.node.height.toString() } />
                <meta property="og:image:alt" content={ data.wordpressPage.featuredImage?.node.altText } />

                { data.wordpressPage.seo.twitterDescription && <meta content={ data.wordpressPage.seo.twitterDescription } name="twitter:description" /> }
                { data.wordpressPage.seo.twitterTitle && <meta content={ data.wordpressPage.seo.twitterTitle } name="twitter:title" /> }

                <script type="application/ld+json" className="yoast-schema-graph">
                    { data.wordpressPage.seo.schema.raw }
                </script>
            </Helmet>
            <FeaturedImage
                className={ classNames.featuredImage }
                gatsbyImageData={ data.wordpressPage.featuredImage?.node.localFile?.childImageSharp.gatsbyImageData }
            />
            <div className={ classNames.container }>
                <BreadCrumbs
                    breadCrumbs={ data.wordpressPage.seo.breadcrumbs }
                />
                <article className={ clsx(classNames.article, classNames.news) }>
                    <Heading className={ classNames.heading } element="h1" variant="large">
                        { data.wordpressPage.title }
                    </Heading>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockOne }>
                            <Box className={ classNames.box } dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
                            <Employees />
                        </div>
                        <div className={ classNames.blockTwo }>
                            <BoxCompare />
                        </div>
                    </div>
                </article>
            </div>
        </Main>
    )
}

export default AboutUsPage;