import React from "react";
import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";

import FeaturedImage from "@js/component/FeaturedImage";
import * as classNames from "@css/component/Cards.module.scss";
import Heading from "@js/component/Heading";

import clsx from "clsx";

import {graphql, Link} from "gatsby";
import { useStaticQuery } from "gatsby";

import Box from "@js/component/Box";

/**
 * @type AllWordpressEmployeeData
 */
type AllWordpressEmployeeData = {
    allWordpressEmployee: {
        edges: {
            node: {
                featuredImage?: {
                    node: {
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                        altText: string;
                        height: number;
                        mimeType: string;
                        sourceUrl: string;
                        width: number;
                    };
                };
                id: string;
                cptEmployees: {
                    nodes: {
                        name: string
                    }[];
                };
                title: string;
                slug: string;
            };
        }[];
    };
};

/**
 * @type EmployeesBlockProps
 */
export type EmployeesBlockProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;

/**
 * @const EmployeesBlock
 */
const EmployeesBlock = (props: EmployeesBlockProps) => {
    const {
        className,
        ...restProps
    } = props;

    const data: AllWordpressEmployeeData = useStaticQuery(graphql`
        query {
            allWordpressEmployee(sort: { fields: date, order: DESC }) {
                edges {
                    node {
                        featuredImage {
                            node {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(height: 600, aspectRatio: 0.75, quality: 100)
                                    }
                                }
                                altText
                                height
                                mimeType
                                sourceUrl
                                width
                            }
                        }
                        id
                        cptEmployees {
                            nodes {
                                name
                           } 
                        }
                        title
                        slug
                    }
                }
            }
        }
    `);

    return (
        <>
            { data.allWordpressEmployee.edges.length > 0 &&

                <Box className={ classNames.wrapper }>
                    <Heading className={ classNames.heading } element="h2" variant="medium">
                        Het Label A-Team
                    </Heading>
                    <div className={ clsx(classNames.cards, classNames.employees) } { ...restProps }>
                        { data.allWordpressEmployee.edges
                            .map(item =>
                                <Link
                                    className={ classNames.link }
                                    to={`/over-ons/${ item.node.slug }`}
                                    key={ item.node.id }
                                >
                                    <Box className={ classNames.box }>
                                        <FeaturedImage
                                            gatsbyImageData={ item.node.featuredImage?.node.localFile.childImageSharp.gatsbyImageData }
                                            variant="employee"
                                        />
                                        <div className={ classNames.content }>
                                            <Heading className={ classNames.heading } element="h2" variant="small">
                                                { item.node.title }
                                            </Heading>
                                            {item.node.cptEmployees &&
                                                <span>
                                                    { item.node.cptEmployees.nodes.map(jobTitle =>
                                                        jobTitle.name
                                                    ).join(', ')}
                                                </span>
                                            }
                                        </div>
                                    </Box>
                                </Link>
                            )}
                    </div>
            </Box> }
        </>
    );
};

export default EmployeesBlock;
