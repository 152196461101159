// extracted by mini-css-extract-plugin
export var box = "Cards-module--box--5Ez4i";
export var cards = "Cards-module--cards--DRQkY";
export var content = "Cards-module--content--Ut-Xw";
export var date = "Cards-module--date--+txCO";
export var employees = "Cards-module--employees--5l+Ju";
export var heading = "Cards-module--heading--sdPX2";
export var icon = "Cards-module--icon--vw9wd";
export var link = "Cards-module--link----m09";
export var noMargin = "Cards-module--noMargin--XYqYH";
export var wrapper = "Cards-module--wrapper--K9Jgo";